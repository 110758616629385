<template>
  <Chatbox :chatroomId="$route.params.id" />
</template>

<script>
import Chatbox from '@/pages/Liff/Chatroom/Chatbox'

export default {
  components: {Chatbox}
};
</script>
